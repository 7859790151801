// Dependencies
import React from "react"
// Components
import Layout from "../components/Layout"

// trigger

const NotFoundPage = () => (
  <Layout title="404: Not found">
    <h1>NOT FOUND</h1>
    <p>Go back to the homepage <a href="/">www.mscty.space</a></p>
  </Layout>
)

export default NotFoundPage
